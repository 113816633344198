<template>
  <div>
    <div class="p-2 mt-2">
      <b-button
        variant="success"
        @click="addDocModal"
        size="sm"
      >
        {{btnLabel}}
      </b-button>
    </div>
    <modal-add-document
      :id="modalId"
      :entityType="entityClass"
      :entityId="entityId"
      :on-success="onAddSuccess"
      :document-entity-type="documentEntityType"
    />
  </div>
</template>

<script>
const ModalAddDocument = () => import('@/components/documents/ModalAddDocument.vue');

export default {
  name: 'NewDocUpload',
  components: { ModalAddDocument },
  props: {
    entityClass: String,
    entityId: String,
    documentEntityType: String,
    onSuccess: Function,
  },
  computed: {
    btnLabel() {
      if (this.entityClass === 'INVOICE') {
        return 'Importa una fattura';
      }
      return 'Aggiungi documento';
    },
    modalId() {
      return 'reportAddDocs';
    },
  },
  methods: {
    addDocModal() {
      // console.log('opening modal', this.entityId, this.entityClass, this.documentEntityType, this.modalId);
      this.$bvModal.show(this.modalId);
    },
    onAddSuccess(data) {
      if (this.onSuccess) {
        this.onSuccess(data);
      }
    },
  },
};
</script>

<style scoped>

</style>
